const features = [
    'Créez vos plannings en 2H',
    'Assurez-vous de la conformité des plannings',
    "Ajustez vos plannings grâce à l'intelligence artificielle",
    'Faites signer électroniquement vos contrats',
    'Vos plannings se mettent à jour en temps réel',
    'Vos éléments variables de paie se calculent automatiquement',
]

export default features
